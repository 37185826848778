<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img style="width: 60%" fluid :src="sideImg" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
            Chào mừng bạn đến với CSM Logistic 👋
          </b-card-title>
          <b-card-text class="mb-2"> Đăng nhập </b-card-text>

          <!-- form -->
          <validation-observer ref="loginForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- Username -->
              <b-form-group label-for="login-username">
                <validation-provider
                  #default="{ errors }"
                  name="username"
                  vid="username"
                  rules="required"
                >
                  <b-form-input
                    id="login-username"
                    v-model="username"
                    :state="errors.length > 0 ? false : null"
                    name="login-username"
                    placeholder="Tên người dùng"
                  />
                  <small class="text-danger">{{
                    handleError(errors[0], "username")
                  }}</small>
                </validation-provider>
              </b-form-group>

              <!--password -->
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Mật khẩu"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{
                    handleError(errors[0], "password")
                  }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
                class="mt-2"
              >
                <template v-if="loading">
                  <Spinner variant="light" />
                </template>

                <template v-else> Đăng nhập </template>
              </b-button>
              <div style="font-size: 12px" class="d-flex align-items-center">
                <div class="mt-2 mr-1">
                  <a href="/contact-us" style="color: rgb(216, 214, 222)"
                    >Liên hệ</a
                  >
                </div>
                <div class="mt-2 mr-1">
                  <a href="/privacy" style="color: rgb(216, 214, 222)"
                    >Chính sách</a
                  >
                </div>
                <div class="mt-2 mr-1">
                  <a href="/terms" style="color: rgb(216, 214, 222)"
                    >Điều khoản sử dụng</a
                  >
                </div>
              </div>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { required } from "@validations";
import {
  BButton,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BRow,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions, mapState } from "vuex";
// import { VueRecaptcha } from 'vue-recaptcha'
import { handleError } from "@/helpers/error";
import useToastHandler from "@/services/toastHandler";
import Spinner from "@core/components/Spinner";
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    // VueRecaptcha,
    Spinner,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      username: "",
      password: "",
      sideImg: require("@/assets/images/logo/csm_logo.png"),
      required,
    };
  },
  computed: {
    ...mapState("userAuth/login", ["loading", "error"]),
    ...mapState("auth", ["ability"]),
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    ...mapActions({
      userLogin: "userAuth/login/login",
    }),

    async login() {
      const valid = await this.$refs.loginForm.validate();
      if (!valid) return;

      const { username, password } = this;
      const user = await this.userLogin({
        username,
        password,
      });
      this.$ability.update(this.ability);
      this.$router.replace("/").then(() => {
        this.showToastSuccess(
          `Chào mừng ${user.fullName}`,
          `Bạn đã đăng nhập thành công với vai trò là ${user.roleName}`
        );
      });
    },
  },
  setup() {
    const { showToastSuccess, showToastError } = useToastHandler();
    return { showToastSuccess, showToastError, handleError };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
